import Plyr from "plyr";
import barba from "@barba/core";
import {gsap} from "gsap";
import Swiper from "swiper/bundle";
import throttle from "lodash.throttle";

/**
 * Sets the vh size via JS. This accounts for the little menu toolbars that
 * appear on a phone.
 *
 * https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
function setVhSize() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
}

window.addEventListener("resize", throttle(setVhSize, 500));
setVhSize();

const icons = {
    'soundOff': '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4"><path stroke-linecap="round" stroke-linejoin="round" d="M17.25 9.75L19.5 12m0 0l2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6l4.72-4.72a.75.75 0 011.28.531V19.94a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.506-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.395C2.806 8.757 3.63 8.25 4.51 8.25H6.75z" /></svg>',
    'soundOn': '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4"><path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" /></svg>'
}

function initializeVideos() {
    /* initialize our video player */
    Array.from(document.querySelectorAll("[data-video]")).forEach((player) => {
        new Plyr(player, {
            controls: ["play", "progress", "mute", "volume"],
            ratio: "16:9",
        });

    });
}

function handleReel() {
    const reel = document.querySelector('#reel');
    const indicator = document.querySelector('#indicator');
    if (!reel || !indicator) {
        return;
    }

    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const intersectionRatio = entry.intersectionRatio * 100;
                if (intersectionRatio >= 50) {
                    indicator.classList.add('opacity-0')
                    indicator.classList.remove('opacity-100');
                }
            } else {
                indicator.classList.remove('opacity-0')
                indicator.classList.add('opacity-100')
            }
        })
    }, {
        root: null,
        threshold: 0.5
    })

    observer.observe(reel)
}
handleReel();

/**
 * Homepage slider
 */
function initializeSlider() {
    const wrapper = document.querySelector('#sizzle');
    if (wrapper) {
        const sizzle = wrapper.querySelector('video');

        if (sizzle) {
            sizzle.currentTime = 0;
            sizzle.play();
        }

        const muteButton = wrapper.querySelector('[data-mute]');
        if (muteButton) {
            muteButton.addEventListener('click', (evt) => {
                sizzle.muted = !sizzle.muted;

                muteButton.innerHTML = sizzle.muted ? `${icons.soundOn}<span data-mute-text>Sound On</span>` :
                    `${icons.soundOff}<span data-mute-text>Sound Off</span>`;
            })
        }
    }

    const next = document.getElementById("btnNext");
    const swiper = new Swiper(".swiper-container", {
        direction: "vertical",
        mousewheel: true,
        keyboard: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },

        on: {
            slideChange: function () {
                if (swiper.activeIndex === swiper.slides.length - 1) {
                    next.classList.add("flip");
                } else {
                    next.classList.remove("flip");
                }
            },
        },
    });

    if (next) {
        next.addEventListener("click", function () {
            if (swiper.isEnd) {
                swiper.slideTo(0);
            } else {
                swiper.slideNext();
            }
        });
    }
}

barba.hooks.beforeEnter(function () {
    window.scrollTo(0, 0);
});
barba.hooks.afterEnter(function ({next}) {
    const {namespace} = next;
    initializeVideos();
    if (namespace === "home") {
        initializeSlider();
    }
});

barba.init({
    transitions: [
        {
            name: "opacity-transition",
            leave(data) {
                return gsap.to(data.current.container, {
                    opacity: 0,
                    duration: 1.25,
                    ease: "power4.out",
                });
            },
            enter(data) {
                return gsap.from(data.next.container, {
                    opacity: 0,
                    duration: 1.25,
                    ease: "power4.out",
                });
            },
        },
    ],
});
